// src/App.js
import React from 'react';
import { Container, Header, Heading, HorizontalRule, List, ListItem, Logo, Paragraphs, StyledA, StyledLink, Title } from '../styles/PageStyles';
import VeidtLogo from '../componets/logo';




function HomePage() {
  return (
      <Container>
        <Header>
          <VeidtLogo/>
          <Title>Veidt</Title>
        </Header>
        <HorizontalRule />
        <Paragraphs>
        Veidt is a Venture strategy company. We specialize in helping founders overcome competition, find venture funding, target new markets, and hone their outreach strategy. We are masters at networking and problem solving. We like to work with driven innovators who are striving to make a difference in the global community. With our background in deep analytical research paired with expert data synthesization, we offer unparalleled insights into market dynamics. Our approach involves meticulous information gathering, enabling us to provide strategic intelligence that keeps our clients ahead of the curve. At Veidt, we believe in turning every stone to ensure our clients' success – your vision, empowered by our discreet yet comprehensive market understanding.
        </Paragraphs>
        
        
        <Heading>People</Heading>
        <HorizontalRule />
        <List>
          <ListItem><StyledA href="https://www.linkedin.com/in/henri-francois-85ba24177/" target="_blank">Henri Francois</StyledA></ListItem>
          <ListItem><StyledA href="https://www.linkedin.com/in/jacob-brouillard-61130b178/" target="_blank">Jacob Brouillard</StyledA></ListItem>
        </List>
        
        <Heading>Offerings</Heading>
        <HorizontalRule />
        <List>
          <ListItem><StyledLink to="/ozymandias">Ozymandias</StyledLink>, our most thorough strategic research.</ListItem>
          <ListItem><StyledLink to="/caligula">Caligula</StyledLink>, a deep audit of the competitive landscape.</ListItem>
          <ListItem><StyledLink to="/nero">Nero</StyledLink>, a full fledged restructuring to cut the fat and make you venture ready.</ListItem>
        </List>
        
        <Heading>Beliefs</Heading>
        <HorizontalRule />
        <List>
          <ListItem><StyledLink to="/who">Who we are</StyledLink></ListItem>
          <ListItem><StyledLink to="/how">How we operate</StyledLink></ListItem>
          <ListItem><StyledLink to="/what">What we believe in</StyledLink></ListItem>
        </List>
      </Container>
  );
}

export default HomePage;