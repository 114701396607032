import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  margin: 60px 0 40px 100px;
  max-width: 700px;

  @media (max-width: 768px) {
    margin: 60px 20px 40px 20px; // Adjust margins for smaller screens
    max-width: 100%; // Allow the container to fill the screen on smaller devices
  }
`;

export const Header = styled.header`
  position: relative;
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  font-size: 33px;
  margin-bottom: -10px;
  font-weight: 700; // Adjusted for boldness, if 900 is not supported
  color: #333;

  @media (max-width: 768px) {
    padding-left: 37px; // Adjust title padding for smaller screens
  }
`;

export const Heading = styled.h2`
  font-size: 24px;
  margin: 30px 0 -15px 0; // Increased space around headings
  font-weight: 700;
  color: #333;
`;

export const Paragraphs = styled.p`
  max-width: 700px;
  font-size: 16px;
  line-height: 1.6;
  margin: -10px 0 10px 0; // Reduced bottom margin
  font-weight: 600;
  color: #333;
`;

export const HorizontalRule = styled.hr`
  border: 0;
  height: 1px;
  background-color: #333;
  margin: 20px 0;
`;

export const StyledA = styled.a`
  color: #0066FF; // Updated link color
  text-decoration: none;
  font-weight: bold; // Adjust weight as needed
  &:hover {
    color: rgb(55, 0, 92);
    text-decoration: underline;
  }
`;

export const StyledLink = styled(Link)`
  color: #0066FF; // Updated link color
  text-decoration: none;
  font-weight: bold; // Adjust weight as needed
  &:hover {
    text-decoration: underline;
  }
`;

export const List = styled.ul`
  max-width: 650px;
  line-height: 1.6;
  font-weight: bold;
  list-style: none;
  margin: -10px 0 10px 0; // Reduced bottom margin
  padding-left: 20px; // Reduced indentation

  @media (max-width: 768px) {
    padding-left: 10px; // Even less indentation for smaller screens
  }
`;

export const ListItem = styled.li`
  margin-bottom: 5px;
  color: #333;

  &:before {
    content: '-';
    padding-right: 10px;
    color: #333;
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;