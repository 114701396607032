import React from 'react';
import { Container, Header, HorizontalRule, Logo, Paragraphs, StyledLink, Title } from '../styles/PageStyles';
import VeidtLogo from '../componets/logo';

const NeroPage = () => {
  return (
    <Container>
      <Header>
        <VeidtLogo/>
        <Title>Nero</Title>
      </Header>
      <Paragraphs>
      Nero is our comprehensive restructuring service, tailored for ventures preparing for significant growth or funding rounds. This offering focuses on 'cutting the fat' – streamlining operations, optimizing resource allocation, and enhancing overall business efficiency. We conduct a thorough analysis of your business structure, processes, and strategies, identifying areas for improvement and potential risks. Our aim with Nero is to not only prepare your venture for the challenges ahead but to transform it into a lean, agile, and investment-attractive entity. We ensure that every aspect of your venture is fine-tuned for maximum performance and readiness for growth or investment.
      </Paragraphs>
      <HorizontalRule />
      <StyledLink to="/apply-now">Apply Now</StyledLink>
      <HorizontalRule />
      <StyledLink to="/">back</StyledLink>
    </Container>
  );
};

export default NeroPage;