import React from 'react'
import styled from 'styled-components';

const Logo = styled.img`
  position: absolute;
  width: 35px;
  height: 35px;
  left: -40px;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: 768px) {
    left: 0; // Adjust logo position for smaller screens
  }
`;

export default function VeidtLogo() {
  return (
    <Logo src="https://veidtassets.s3.amazonaws.com/Veidt.png" alt="Ozymandias Logo" />
  )
}
