// src/styles/GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Times New Roman';
    font-size: 16px;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background: #fff;
  }

  a {
    color: blue;
    text-decoration: none;
  }

  a:hover {
    color: rgb(55, 0, 92);
    text-decoration: underline;
  }
`;

export default GlobalStyles;
