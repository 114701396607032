import { useState, useEffect } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import HomePage from "./pages/Homepage";
import OzymandiasPage from "./pages/Ozymandias";
import CaligulaPage from "./pages/Caligula";
import NeroPage from "./pages/Nero";
import WhoPage from "./pages/WhoPage";
import WhatPage from "./pages/WhatPage";
import HowPage from "./pages/HowPage";

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <HomePage />,
      index: true,
    },
    {
      path: "/ozymandias",
      element: <OzymandiasPage />,
      index: true,
    },
    {
      path: "/caligula",
      element: <CaligulaPage />,
      index: true,
    },
    {
      path: "/nero",
      element: <NeroPage />,
      index: true,
    },
    {
      path: "/who",
      element: <WhoPage />,
      index: true,
    },
    {
      path: "/what",
      element: <WhatPage />,
      index: true,
    },
    {
      path: "/how",
      element: <HowPage />,
      index: true,
    },
  ]);

  return routes;
}
