import React from 'react';
import { Container, Header, HorizontalRule, Logo, Paragraphs, StyledLink, Title } from '../styles/PageStyles';
import VeidtLogo from '../componets/logo';

const OzymandiasPage = () => {
  return (
    <Container>
      <Header>
        <VeidtLogo/>
        <Title>Ozymandias</Title>
      </Header>
      <Paragraphs>
        Ozymandias is our most thorough strategic research service. We conduct in-depth market analysis and research to provide our clients with comprehensive insights into market dynamics, helping them stay ahead in their respective industries. Our team of experts utilizes advanced methodologies to gather strategic intelligence, ensuring that our clients can make informed decisions based on the latest market trends.
      </Paragraphs>
      <HorizontalRule />
      <StyledLink to="/apply-now">Apply Now</StyledLink>
      <HorizontalRule />
      <StyledLink to="/">back</StyledLink>
    </Container>
  );
};

export default OzymandiasPage;
