import React from 'react';
import { Container, Header, HorizontalRule, Logo, Paragraphs, StyledLink, Title } from '../styles/PageStyles';
import VeidtLogo from '../componets/logo';

const CaligulaPage = () => {
  return (
    <Container>
      <Header>
        <VeidtLogo/>
        <Title>Caligula</Title>
      </Header>
      <Paragraphs>
      Caligula is our deep-dive audit designed to thoroughly understand and dissect the competitive landscape of your industry. This service is focused on identifying your key competitors, their strategies, strengths, weaknesses, and market positions. We conduct an exhaustive analysis to understand market share distribution, competitor product offerings, pricing strategies, and customer perceptions. Our insights will equip you with the knowledge to make informed strategic decisions, identify gaps in the market, and explore areas for innovation and growth. Caligula is the key to unlocking your competitive advantage in a crowded marketplace.
      </Paragraphs>
      <HorizontalRule />
      <StyledLink to="/apply-now">Apply Now</StyledLink>
      <HorizontalRule />
      <StyledLink to="/">back</StyledLink>
    </Container>
  );
};

export default CaligulaPage;