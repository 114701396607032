import React from 'react';
import { Container, Header, HorizontalRule, Logo, Paragraphs, StyledLink, Title } from '../styles/PageStyles';
import VeidtLogo from '../componets/logo';

const HowPage = () => {
  return (
    <Container>
      <Header>
        <VeidtLogo/>
        <Title>Our Opperation</Title>
      </Header>
      <Paragraphs>
        
      </Paragraphs>
      <HorizontalRule />
      <StyledLink to="/">back</StyledLink>
    </Container>
  );
};

export default HowPage;