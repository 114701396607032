import React from "react"; // Import useEffect
import { BrowserRouter } from 'react-router-dom';
// routes
import Router from "./routes";
import { HelmetProvider } from "react-helmet-async";
import GlobalStyles from "./styles/GlobalStyles";


// ----------------------------------------------------------------------

export default function App() {
  // Dependency array with dispatch to ensure useEffect runs once

  return (
    <HelmetProvider >
      <BrowserRouter>
      <GlobalStyles/>
      <Router />
      </BrowserRouter>
    </HelmetProvider>
  );
}
